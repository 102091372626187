import React, { useEffect } from 'react'
import './landind.scss'
import Sectionne from './SectionOne/index'
import SectionTwo from './SectionTwo'
import ThridSection from './SectionThree/Sectionthree'
import Fourth from './Sectionfour/index'
import Five from './SectionFive/index'
import Six from './SectionSix'
import Seven from './SevenSeven/index'
import Eight from './EightSection/index'
import Nine from './Nine/MineSection'
import ContantNav from '../LandingPage'
import Ten from './Ten/Ten'
import Thriteen from './ThirteenSection'
// import NavMenu from './'
import Footer from '../../components/Footer/Footer'
export default function Index() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>   <ContantNav />
            <div
                className='mainthibg'

            >

                {/* <NavMenu /> */}

                {/**/}
                <Sectionne />
                {/* <SectionTwo /> */}
                <ThridSection />
                <Fourth />
                <Five />
                {/* <Six />
                <Seven />
                <Eight />
                <Nine />
                <Ten />
                <Eleven />
                <Thriteen />
                <Footer /> */}
                <Footer />
            </div>
        </>
    )
}
