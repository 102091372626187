import React from 'react'
import './Sectionthree.scss'

import b1 from '../../../assets/b1.svg'
import Marquee from "react-fast-marquee";
export default function Sectionthree() {
    return (
        <div className='sectionthree'>

            <div className='inner'>
                <div className='headertext'>Who should join EarnGPT?</div>


                <Marquee gap={20}
                    speed={20}
                    loop={0}
                    delay={0}
                    direction={"right"}
                    gradientWidth={300}
                >
                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>
                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>
                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>

                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>
                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>
                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>
                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>
                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>
                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>
                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>
                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>
                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>
                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>
                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>
                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>
                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>
                    <div className='cartlist'>
                        <img src={b1} />
                        <div>
                            <div className='cardtexthderd'>Is it possible to make money with Ai? </div>
                            <div className='innertextline'>Fortunately YES, but most people don’t understand how big of an opportunity ai really is.</div>
                        </div>
                    </div>


                </Marquee>


            </div>
        </div>
    )
}
