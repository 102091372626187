
import React, { Component, useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Homepage from '../src/pages/Customlanding';
import Invstorpage from '../src/pages/Investor'
import BusinessOwner from './pages/BussinessOwner/BusinessOwner';
import "./App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import CaseStudyPage from './pages/CaseStudyPage/index'
import FounderPage from './pages/FounderPage/FounderPage';
import { GlobalContext } from "./context/globalContext";
import Chatgpt from './pages/Chatgpt/Chatgpt';
import Alumni from './pages/Customlanding/Alumni'
import Consultants from './pages/Consultant/index'
import HomePage from './pages/Customlanding/Homepage'
import FreeResoure from './pages/Customlanding/FreeResourse'
import Programs from './pages/Customlanding/Programs'
import Tools from './pages/Customlanding/Tools'
import SuperNova from './pages/Customlanding/SuperNova'
import Ideastomvp from './pages/Customlanding/Ideastomvp'
import Marketplaces from './pages/Customlanding/Marketing.js'
export default function App() {
  const [selectedProfileData, setSelectedProfileData] = useState("");

  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY) {
      // User is scrolling down
      setShowNavbar(false);
    } else {
      // User is scrolling up
      setShowNavbar(true);
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);
  const value = {
    showNavbar,
    selectedProfileData,
    setSelectedProfileData,
  };
  useEffect(() => {

    AOS.init({
      disable: window.innerWidth < 768,

      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <GlobalContext.Provider value={value}>
      <div>
        {/* <Homepage /> */}

        <Router>

          <Switch>

            {/* <Redirect exact from="/" to="/founders" /> */}
            {/* <Redirect exact from="/:pathid" to="/founders/:pathid" /> */}
            <Route exact path="/" component={HomePage} />
            {/*  */}
            <Route exact path="/founders/shorupan" component={FounderPage} />
            <Route exact path="/chat" component={Chatgpt} />
            <Route exact path="/founders/:pathid" component={Homepage} />
            <Route exact path="/founders" component={Homepage} />
            <Route exact path="/alumni" component={Alumni} />
            <Route exact path="/consultants" component={Consultants} />
            <Route exact path="/consultants/:pathid" component={Consultants} />
            <Route exact path="/freeResources" component={FreeResoure} />
            <Route exact path="/freeResources/:pathid" component={FreeResoure} />
            <Route exact path="/tools" component={Tools} />
            <Route exact path="/tools/:pathid" component={Tools} />
            <Route exact path="/supernova" component={SuperNova} />
            <Route exact path="/supernova/:pathid" component={SuperNova} />
            <Route exact path="/marketplaces" component={Marketplaces} />
            <Route exact path="/marketplaces/:pathid" component={Marketplaces} />
            <Route exact path="/ideastomvp" component={Ideastomvp} />
            <Route exact path="/ideastomvp/:pathid" component={Ideastomvp} />
            <Route exact path="/programs" component={Programs} />
            <Route exact path="/programs/:pathid" component={Programs} />
            <Route exact path="/investors" component={Invstorpage} />
            <Route exact path="/investors/:pathid" component={Invstorpage} />
            <Route exact path="/businessOwners" component={BusinessOwner} />
            <Route exact path="/businessOwners/:pathid" component={BusinessOwner} />
            <Route exact path="/casestudies/:casestudy" component={CaseStudyPage} />
            <Route exact path="/:pathid" component={HomePage} />
            {/* <Redirect exact from="/:pathid" to="/founders/:pathid" /> */}
            {/* <Route  path="/Compare/:code" component={CompareHacker}/>
      <Route  path="/Exchange/Bibox" component={Bibox}/>
      <Route  path="/Exchange/Binance" component={Binance}/>
      <Route  path="/Exchange/Bittrex" component={Bittrex }/>
      <Route  path="/Exchange/Bitfinex" component={Bitfinex}/>
      <Route  path="/Exchange/Coss" component={Coss}/>
      <Route  path="/Exchange/Kraken" component={Kraken}/>
      <Route  path="/Exchange/Kucoin" component={Kucoin}/>
      <Route  path="/Exchange/Poloniex" component={Poloniex}/>
      <Route  path="/Exchange/Upbit" component={Upbit}/>
      <Route  path="/Compare" component={Compare}/> */}
          </Switch>

        </Router>

      </div>
    </GlobalContext.Provider>
  )
}
