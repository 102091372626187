import React from 'react'
import rocket from '../../../assets/rocket.png'
import './SectionOne.scss'
import Marquee from "react-fast-marquee";
import a1 from '../../../assets/a1.svg'
import a2 from '../../../assets/a2.svg'
import a3 from '../../../assets/a3.svg'
export default function index() {
    return (
        <div className='landingSection'>
            <div className='innersectionone'>
                <div className='top-content'>
                    <div className='hearder'>Monetize The AIRevolution</div>
                    <div className='subtext'>Master GenAI & become an Ai powered consultant and <br /> businesses will pay you to manage Ai Agents & GPT Affiliates</div>
                    <div className='label'
                        onClick={() => window.open("https://join.garageuniversity.app/web/checkout/66eb3d4c48dee9a0c6999e05", "_blank")}
                    >Apply Now</div>
                    <div className='textlabel'>First cohort begins on October 1st 2024</div>
                </div>
                <div className='sectioncard'>
                    <div className='cardSectionOne'>
                        <img src={a1} />
                        <div class="cardtext"><span>1</span><div class="cardtextHeader">Take the EarnGPT Pro course</div></div>
                        <div className='para'>Unlock the skills, community, and earning potential needed to thrive as an AI consultant.</div>
                    </div>
                    <div className='cardSectionOne'>
                        <img src={a2} />
                        <div class="cardtext"><span>2</span><div class="cardtextHeader">Create your Ai consultancy</div></div>
                        <div className='para'>We will help you setup your Ai consultancy by setting up your website, your services and processes.</div>
                    </div>
                    <div className='cardSectionOne'>
                        <img src={a3} />
                        <div class="cardtext"><span>3</span><div class="cardtextHeader">Plug your AiC into Garage University</div></div>
                        <div className='para'>If you don’t want to do the services, then you can white-label all the Ai formation & transformation service from us</div>
                    </div>
                </div>
            </div>

        </div>
    )
}
