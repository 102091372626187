import React, { useState, useContext, useEffect } from 'react'
import './BusinessOwner.scss'
import BusninneNav from '../LandingPage'
import socailty from '../../images/socailty.svg'
import { GlobalContext } from "../../context/globalContext";
import cards from '../../images/cards.svg'
import Footer from "../../components/Footer/Footer"
export default function BusinessOwner() {
    const {
        selectedProfileData,
        setSelectedProfileData,
    } = useContext(GlobalContext);
    const [detail, setdetail] = useState("Ai Audit")
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <BusninneNav />
            <div className='mainbusinnessSection'>
                <div className='topbussiness'>
                    <div className='inner'
                        data-aos={window.innerWidth >= 900 ? "zoom-in" : ""}
                    >
                        <div>
                            <div className='headertext'>Get An Ai Business Audit Today</div>
                            <div className='paratext'>Our Ai Business Consultants will examine where you save money with Ai  </div>
                        </div>

                        <div className="bookSession"

                            onClick={() => window.open(`${selectedProfileData == "" ? "https://join.garageuniversity.app/web/checkout/67123a63b31eeb5f33981885" : `https://join.garageuniversity.app/web/checkout/67123a63b31eeb5f33981885?affiliate=${selectedProfileData}`}`, "_blank")}
                        >Book Audit Session</div>
                    </div></div>
                <div className='bussinesOwner'>
                    <div className='rightend'>
                        <div className='inner'>

                            <div className='right'
                                data-aos={window.innerWidth >= 900 ? "fade-up" : ""}
                            >
                                <div className='headers'>Ai Business Manager</div>
                                <div className='tect'>Reinvent your existing business by cutting costs and driving automation by hiring ai agents with our ABM programs.</div>
                                <div className='label'
                                    onClick={() => window.open(`${selectedProfileData == "" ? "https://join.garageuniversity.app/web/checkout/67123a63b31eeb5f33981885" : `https://join.garageuniversity.app/web/checkout/67123a63b31eeb5f33981885?affiliate=${selectedProfileData}`}`, "_blank")}
                                >Book Audit Session</div>
                                <img src={socailty} />
                            </div>

                            <div className='left' data-aos={window.innerWidth >= 900 ? "fade-up" : ""}>
                                <div className='headers'
                                    style={{
                                        color: "#FBD10D"

                                    }}
                                >Ai Viral Marketer</div>
                                <div className='tect'

                                    style={{
                                        color: "#fff"

                                    }}
                                >Supercharge your marketing efforts by deploying an Ai first marketing strategy managed by our AVM programs.</div>
                                <div className='label'
                                    onClick={() => window.open(`${selectedProfileData == "" ? "https://join.garageuniversity.app/web/checkout/67123a63b31eeb5f33981885" : `https://join.garageuniversity.app/web/checkout/67123a63b31eeb5f33981885?affiliate=${selectedProfileData}`}`, "_blank")}
                                >Book Audit Session</div>
                            </div>
                        </div>       </div>
                </div>

                <div className='section3'>
                    <div className='inner' >
                        <div className='texttitle'>Bring Your Company Into The <br />
                            Ai Business Revolution (ABR)</div>
                        <div class="cardMianprototyping">
                            <div class="leftside"
                                data-aos={window.innerWidth >= 900 ? "zoom-out" : ""}
                            >
                                <div class="cardPrototying"
                                    style={{
                                        boxShadow: detail == "Ai Audit" && "0px 4px 4px 0px #00000040",
                                        background: detail == "Ai Audit" && "#0000000D"
                                    }}
                                    onClick={() => setdetail("Ai Audit")}
                                >
                                    <div class="textForcard">Ai Audit</div>
                                    <div class="textforcardoara">Reinvent your existing business by cutting costs and driving automation by hiring ai agents with our ABM programs.</div>
                                </div>
                                <div class="cardPrototying"
                                    style={{
                                        boxShadow: detail == "Build Ai Agents To Replace Employees" && "0px 4px 4px 0px #00000040",
                                        background: detail == "Build Ai Agents To Replace Employees" && "#0000000D"
                                    }}
                                    onClick={() => setdetail("Build Ai Agents To Replace Employees")}
                                >
                                    <div class="textForcard">Build Ai Agents To Replace Employees</div>
                                    <div class="textforcardoara">Reinvent your existing business by cutting costs and driving automation by hiring ai agents with our ABM programs.</div>
                                </div>
                                <div class="cardPrototying"
                                    style={{
                                        boxShadow: detail == "Reinvest Savings Into Ai Marketing" && "0px 4px 4px 0px #00000040",
                                        background: detail == "Reinvest Savings Into Ai Marketing" && "#0000000D"


                                    }}
                                    onClick={() => setdetail("Reinvest Savings Into Ai Marketing")}
                                >
                                    <div class="textForcard">Reinvest Savings Into Ai Marketing</div>
                                    <div class="textforcardoara">Reinvent your existing business by cutting costs and driving automation by hiring ai agents with our ABM programs.</div>
                                </div>
                            </div>
                            <div class="rigitside" data-aos={window.innerWidth >= 900 ? "zoom-out" : ""}>
                                <img src={cards} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='section4'>
                    <div className='inner'>
                        <div className='maintitle'>We Provide Managed Ai Agents For Each <br /> Of The Following Departments</div>
                        <div className='contentsection'>
                            <div className='navlabel'
                                data-aos={window.innerWidth >= 900 ? "fade-left" : ""}
                           >
                                <div className='list'
                                    style={{
                                        color: "#000",
                                        background: "#FBD10D"
                                    }}
                                >Legal</div>
                                <div className='list'>Research</div>
                                <div className='list'>Customer Support</div>
                                <div className='list'>Finance</div>
                                <div className='list'>HR</div>
                                <div className='list'>Operations</div>
                            </div>

                            <div className='innerlist'>
                                <div className='left'       data-aos={window.innerWidth >= 900 ? "fade-up" : ""}>
                                    <div className='sidetext'

                                    >Transforming Legal Operations with Automated Agents</div>
                                    <div className='para'>Empower your legal department by building and deploying AI agents that streamline contract management, compliance monitoring, and legal research, enhancing efficiency and reducing operational risks.</div>
                                    <div className='labeltext' style={{
                                        background: "#FBD10D"
                                    }}>Contract Review and Analysis Agent</div>
                                    <div className='labeltext'>Legal Research Agetnt</div>
                                    <div className='labeltext'>IP Management and Patent Agent</div>
                                    <div className='labeltext'>Litigation Risk Assessment Agent</div>

                                </div>
                                <div className='rigit'       data-aos={window.innerWidth >= 900 ? "fade-up" : ""}>
                                    <div>
                                        <div className='number'
                                            style={{
                                                marginTop: "0"
                                            }}
                                        >01</div>
                                        <div className='doc'>Document Ingestion and Preprocessing</div>
                                        <div className='paralist'>The agent uploads and scans the contract, identifying key sections (e.g., terms, conditions, clauses) and converting the document into machine-readable text for analysis.</div>
                                    </div>
                                    <div>
                                        <div className='number'>02</div>
                                        <div className='doc'>Clause Identification and Extraction</div>
                                        <div className='paralist'>Using NLP (Natural Language Processing), the agent identifies and categorizes clauses, such as confidentiality, liability, and termination, tagging them for further analysis based on legal standards and company policies.</div>
                                    </div>
                                    <div>
                                        <div className='number'>03</div>
                                        <div className='doc'>Risk Assessment and Red Flag Detection</div>
                                        <div className='paralist'>The agent evaluates the extracted clauses against a legal database of best practices and regulatory standards, highlighting risky terms or discrepancies, such as ambiguous language, penalties, or missing clauses.</div>
                                    </div>
                                    <div>
                                        <div className='number'>04</div>
                                        <div className='doc'>Suggestions and Reporting</div>
                                        <div className='paralist'>The agent generates a summary report, offering suggested revisions, highlighting critical clauses, and providing risk mitigation strategies to ensure the contract aligns with legal and business objectives.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >


        </>
    )
}
