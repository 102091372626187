import React, { useState, useEffect } from 'react'
import shorupana from '../../images/shorupana.svg'

import largssize from '../../images/largssize.png'
import './Founderpage.scss'
import LandingPage from '../LandingPage'
export default function FounderPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className='founderPage'>
            <LandingPage />
            <div className='inner'>

                <img src={shorupana} />

                <div className='rightside'>
                    <div className='header'>Shorupan P</div>
                    <div className='innertext'>Co-Founder & CEO </div>
                    <div className='para'>Shorupan Pirakaspathy is a seasoned entrepreneur and the founder of Garage University, the world's first AI-powered entrepreneurship school. With a wealth of experience in founding and scaling tech companies, Shorupan has deep expertise in startup operations, investor relations, and navigating the complexities of the tech industry. His passion for empowering aspiring entrepreneurs has driven him to create innovative platforms that bridge the gap between business education and real-world application.</div>
                    <div className='label'
                        onClick={() => window.open("https://shorupan.me/", "_blank")}
                    >Visit Shorupan’s Website</div>
                </div>
            </div>

            <div className='mobileinner'>
                <div className='rightside'>
                    <div className='header'>Shorupan P</div>
                    <div className='innertext'>Co-Founder & CEO </div>
                    <img src={shorupana} />
                    <div className='label'
                        onClick={() => window.open("https://shorupan.me/", "_blank")}
                    >Visit Shorupan’s Website</div>
                    <div className='para'>Shorupan Pirakaspathy is a seasoned entrepreneur and the founder of Garage University, the world's first AI-powered entrepreneurship school. With a wealth of experience in founding and scaling tech companies, Shorupan has deep expertise in startup operations, investor relations, and navigating the complexities of the tech industry. His passion for empowering aspiring entrepreneurs has driven him to create innovative platforms that bridge the gap between business education and real-world application.</div>
                </div>
            </div>
        </div>



    )
}
