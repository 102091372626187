import React from 'react'
import { useState } from 'react'
import p1 from '../../images/k1.svg'
import p2 from '../../images/k2.svg'
import p3 from '../../images/k3.svg'
import p4 from '../../images/k4.svg'
import pro1 from '../../images/pro1.svg'
import pro2 from '../../images/pro2.svg'
import qqq from '../../images/qqq.svg'
import p5 from '../../images/k5.svg'
import p6 from '../../images/k6.svg'
export default function Trems() {
    const [seedata, setseedata] = useState({
        month: "0",
        week: "0",
        toggle: true,
    })
    const [datalist, setdatalist] = useState({
        name: "",

        toggle: true,
    })
    console.log("data", datalist)
    const [infodetail, setinfodetail] = useState("Team")
    return (
        <div>

            <div className='headerlist supernovaheaderlist'>
                <div className='listItems'
                    onClick={() => setinfodetail("Team")}
                    style={{
                        background: infodetail == "Team" && "#000",
                        color: infodetail == "Team" && "white",
                        fontWeight: infodetail == "Team" && "bold"
                    }}
                >Team</div>
                <div className='listItems'
                    onClick={() => setinfodetail("Curriculum")}
                    style={{
                        background: infodetail == "Curriculum" && "#000",
                        color: infodetail == "Curriculum" && "white",
                        fontWeight: infodetail == "Curriculum" && "bold"
                    }}
                >Curriculum</div>
                <div className='listItems'
                    style={{
                        background: infodetail == "Deliverables" && "#000",
                        color: infodetail == "Deliverables" && "white",
                        fontWeight: infodetail == "Deliverables" && "bold"
                    }}
                    onClick={() => setinfodetail("Deliverables")}
                >Deliverables</div>
                <div className='listItems'>Audits</div>
            </div>

            {
                infodetail == "Team" &&
                <div className='imgsectiondiv'>
                    <div className='smallbox'>
                        <div className='textlight'>Founder</div>
                        <img src={p1} />
                    </div>
                    <div className='smallbox'>
                        <div className='textlight'>Implementation <br />Specialist</div>
                        <img src={p2} />
                    </div>
                    <div className='smallbox'>
                        <div className='textlight'>Development <br />
                            Specialist</div>
                        <img src={p3} />
                    </div>
                    <div className='smallbox'>
                        <div className='textlight'>UI/UX <br />
                            Specialist</div>
                        <img src={p4} />
                    </div>
                    <div className='smallbox'>
                        <div className='textlight'>Branding <br />
                            Specialist</div>
                        <img src={p5} />
                    </div>
                    <div className='smallbox'>
                        <div className='textlight'>Product <br />
                            Tester</div>
                        <img src={p6} />
                    </div>
                </div>
            }




            {
                infodetail == "Curriculum" &&
                <>
                    <div className='listofcard'
                        style={{
                            marginTop: "2rem"
                        }}
                    >
                        <div className='sublistofcard'
                            onClick={() => {
                                setseedata({
                                    month: seedata?.month == "1" ? "0" : "1",
                                    week: "",
                                    toggle: false,
                                })
                            }}
                        >
                            <div className='boldheader'>Month 1: Clarity</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                            </svg>
                        </div>
                    </div>
                    {
                        seedata?.month == "1" &&
                        <>
                            <div className='listofcard'>
                                <div className='weekbio'
                                    onClick={() => {
                                        setseedata({
                                            month: "1",
                                            week: "1",
                                            toggle: seedata?.week == "1" ? !seedata?.toggle : true,
                                        })
                                    }}
                                >
                                    <div className='subboldheader'>Week 1: BRD</div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                        <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                                    </svg>
                                </div>
                                {
                                    seedata?.month == "1" && seedata?.week == "1" && seedata?.toggle &&
                                    <div className='cutomdata'>
                                        <>
                                            <div className='tablelist'>
                                                <div className='tableheader'>Leader</div>
                                                <div className='tableheader'>Activity Type</div>
                                                <div className='tableheader'>Participants</div>
                                                <div className='tableheader'>Description</div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation <br />Specialist</div>
                                                <div className='tableheaderbody'>Collective -
                                                    Call
                                                </div>
                                                <div className='tableheaderbody'>Founder</div>
                                                <div className='tableheaderbody'>Call to collect all the data for the BRD.

                                                </div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation <br />Specialist</div>
                                                <div className='tableheaderbody'>Independent -
                                                    Document preparation

                                                </div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>The implementation specialist prepares the first version of BRD.
                                                </div>
                                            </div>


                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation <br />Specialist</div>
                                                <div className='tableheaderbody'>Collective -
                                                    Call


                                                </div>
                                                <div className='tableheaderbody'>Founder</div>
                                                <div className='tableheaderbody'>The implementation specialist presents the BRD to the founder.


                                                </div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Founder</div>
                                                <div className='tableheaderbody'>Independent -
                                                    Document enhancement
                                                    l


                                                </div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>The founder makes the desired changes to the BRD.
                                                </div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation <br />Specialist</div>
                                                <div className='tableheaderbody'>Collective -
                                                    Signoff Call


                                                </div>
                                                <div className='tableheaderbody'>Founder</div>
                                                <div className='tableheaderbody'>The founder and the implementation specialist review and agree on the final version of the BRD.




                                                </div>
                                            </div>
                                        </>
                                    </div>
                                }



                            </div>
                        </>
                    }


                    {
                        seedata?.month == "1" &&
                        <>
                            <div className='listofcard'>
                                <div className='weekbio'
                                    onClick={() => {
                                        setseedata({
                                            month: "1",
                                            week: "2",
                                            toggle: seedata?.week == "2" ? !seedata?.toggle : true,
                                        })
                                    }}
                                >
                                    <div className='subboldheader'>Week 2: PRD</div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                        <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                                    </svg>
                                </div>

                                {
                                    seedata?.month == "1" && seedata?.week == "2" && seedata?.toggle &&
                                    <div className='cutomdata'>
                                        <>
                                            <div className='tablelist'>
                                                <div className='tableheader'>Leader</div>
                                                <div className='tableheader'>Activity Type</div>
                                                <div className='tableheader'>Participants</div>
                                                <div className='tableheader'>Description</div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation <br />Specialist</div>
                                                <div className='tableheaderbody'>Independent -
                                                    Document preparation

                                                </div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>The implementation specialist prepares the “product competitive analysis report”
                                                </div>
                                            </div>

                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation <br />Specialist</div>
                                                <div className='tableheaderbody'>Collective -
                                                    Call
                                                </div>
                                                <div className='tableheaderbody'>Founder</div>
                                                <div className='tableheaderbody'>The implementation specialist presents the “product competitive analysis report” to the founder.

                                                    .
                                                </div>
                                            </div>


                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation <br />Specialist</div>
                                                <div className='tableheaderbody'>Collective -
                                                    Call


                                                </div>
                                                <div className='tableheaderbody'>Founder</div>
                                                <div className='tableheaderbody'>The implementation specialist and the founder co-create the PRD document (call ⅓) </div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation <br />Specialist</div>

                                                <div className='tableheaderbody'>Collective -
                                                    Call



                                                </div>
                                                <div className='tableheaderbody'>Founder</div>
                                                <div className='tableheaderbody'>The implementation specialist and the founder co-create the PRD document (call ⅓)


                                                </div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation <br />Specialist</div>

                                                <div className='tableheaderbody'>Collective -
                                                    Call



                                                </div>
                                                <div className='tableheaderbody'>Founder</div>
                                                <div className='tableheaderbody'>The implementation specialist and the founder co-create the PRD document (call ⅓)


                                                </div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Founder</div>
                                                <div className='tableheaderbody'>Independent -
                                                    Document enhancement


                                                </div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>The founder reviews the completed PRD and performs the final signoff.






                                                </div>
                                            </div>
                                        </>
                                    </div>
                                }


                            </div>
                        </>
                    }

                    {
                        seedata?.month == "1" &&
                        <>
                            <div className='listofcard'>
                                <div className='weekbio'
                                    onClick={() => {
                                        setseedata({
                                            month: "1",
                                            week: "3",
                                            toggle: seedata?.week == "3" ? !seedata?.toggle : true,
                                        })
                                    }}
                                >
                                    <div className='subboldheader'>Week 3: Logo + UI/UX Design Report</div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                        <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                                    </svg>
                                </div>

                                {
                                    seedata?.month == "1" && seedata?.week == "3" && seedata?.toggle &&
                                    <div className='cutomdata'>
                                        <>
                                            <div className='tablelist'>
                                                <div className='tableheader'>Leader</div>
                                                <div className='tableheader'>Activity Type</div>
                                                <div className='tableheader'>Participants</div>
                                                <div className='tableheader'>Description</div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call</div>
                                                <div className='tableheaderbody'>Branding specialist, Founder</div>
                                                <div className='tableheaderbody'>Discuss the vision for the logo.</div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Branding specialist</div>
                                                <div className='tableheaderbody'>Independent - Logo creation</div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>The branding specialist creates the logo proposals.</div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call</div>
                                                <div className='tableheaderbody'>Branding specialist, Founder</div>
                                                <div className='tableheaderbody'>The branding specialist presents the logo options to the founder and implementation specialist and the founder selects one of them.</div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call</div>
                                                <div className='tableheaderbody'>UI/UX designer, Branding specialist, Founder</div>
                                                <div className='tableheaderbody'>The implementation specialist gives a detailed overview to the UI/UX designer on the entire product flow using PRD.</div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>UI/UX designer & branding specialist</div>
                                                <div className='tableheaderbody'>Independent - Document creation</div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>The UI/UX designer prepares a UI/UX design report with proposed themes, colours, and structure.</div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call</div>
                                                <div className='tableheaderbody'>UI/UX designer, Branding specialist</div>
                                                <div className='tableheaderbody'>presents the design report to the founder. The founder can request changes which would invoke another round of calls. Or he can sign off. </div>
                                            </div>
                                        </>
                                    </div>
                                }


                            </div>
                        </>
                    }


                    {
                        seedata?.month == "1" &&
                        <>
                            <div className='listofcard'>
                                <div className='weekbio'
                                    onClick={() => {
                                        setseedata({
                                            month: "1",
                                            week: "3",
                                            toggle: seedata?.week == "3" ? !seedata?.toggle : true,
                                        })
                                    }}
                                >
                                    <div className='subboldheader'>Week 4: Pre-Login Website Wireframes
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                        <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                                    </svg>
                                </div>
                                {
                                    seedata?.month == "1" && seedata?.week == "4" && seedata?.toggle &&
                                    <div className='cutomdata'>
                                        <>
                                            <div className='tablelist'>
                                                <div className='tableheader'>Leader</div>
                                                <div className='tableheader'>Activity Type</div>
                                                <div className='tableheader'>Participants</div>
                                                <div className='tableheader'>Description</div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>UI/UX designer</div>
                                                <div className='tableheaderbody'>Independent - Wireframe creation</div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>The UI/UX designer will prepare all the wireframes for the pre-login website.</div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call</div>
                                                <div className='tableheaderbody'>UI/UX designer, Founder</div>
                                                <div className='tableheaderbody'>
                                                    The UI/UX designer presents each of the screens to the Founder. The founder can request changes which would invoke another round of calls. Or he can sign off.
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                }


                            </div>
                        </>
                    }


                    {/*  */}

                    <div className='listofcard'>
                        <div className='sublistofcard'
                            onClick={() => {
                                setseedata({
                                    month: seedata?.month == "2" ? "0" : "2",
                                    week: "",
                                    toggle: false,
                                })
                            }}
                        >
                            <div className='boldheader'>Month 2: Design</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                            </svg>
                        </div>
                    </div>
                    {
                        seedata?.month == "2" &&
                        <>
                            <div className='listofcard'>
                                <div className='weekbio'
                                    onClick={() => {
                                        setseedata({
                                            month: "2",
                                            week: "1",
                                            toggle: seedata?.week == "1" ? !seedata?.toggle : true,
                                        })
                                    }}
                                >
                                    <div className='subboldheader'>Week 1 - User flow mapping
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                        <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                                    </svg>
                                </div>
                                {
                                    seedata?.month == "2" && seedata?.week == "1" && seedata?.toggle &&
                                    <div className='cutomdata'>
                                        <>  <div className='tablelist'>
                                            <div className='tableheader'>Leader</div>
                                            <div className='tableheader'>Activity Type</div>
                                            <div className='tableheader'>Participants</div>
                                            <div className='tableheader'>Description</div>
                                        </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Independent - Map Creation</div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>The implementation specialist will prepare the Consumer User Flow Map.</div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call</div>
                                                <div className='tableheaderbody'>UI/UX designer, Founder</div>
                                                <div className='tableheaderbody'>
                                                    The implementation specialist will present the Consumer User Flow Map to the founder and UI/UX designer. The founder can request changes which would invoke another round of calls.The implementation specialist will present the Consumer User Flow Map to the founder and UI/UX designer. The founder can request changes which would invoke another round of calls. Or he can sign off.


                                                </div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>
                                                    Implementation Specialist
                                                </div>
                                                <div className='tableheaderbody'>
                                                    Independent - Map Creation
                                                </div>
                                                <div className='tableheaderbody'>
                                                    NA
                                                </div>
                                                <div className='tableheaderbody'>
                                                    The implementation specialist will prepare the Vendor/Admin User Flow Map.
                                                </div>
                                            </div>

                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>
                                                    Implementation Specialist
                                                </div>
                                                <div className='tableheaderbody'>
                                                    Collective - Call
                                                </div>
                                                <div className='tableheaderbody'>
                                                    UI/UX designer, Founder
                                                </div>
                                                <div className='tableheaderbody'>
                                                    The implementation specialist will present the Vendor/Admin User Flow Map to the founder and UI/UX designer. The founder can request changes which would invoke another round of calls. Or he can sign off.
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                }


                            </div>
                        </>
                    }


                    {
                        seedata?.month == "2" &&
                        <>
                            <div className='listofcard'>
                                <div className='weekbio'
                                    onClick={() => {
                                        setseedata({
                                            month: "2",
                                            week: "2",
                                            toggle: seedata?.week == "2" ? !seedata?.toggle : true,
                                        })
                                    }}
                                >
                                    <div className='subboldheader'>Week 2 - Consumer application wireframes

                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                        <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                                    </svg>
                                </div>
                                {
                                    seedata?.month == "2" && seedata?.week == "2" && seedata?.toggle &&
                                    <div className='cutomdata'>
                                        <>
                                            <div className='tablelist'>
                                                <div className='tableheader'>Leader</div>
                                                <div className='tableheader'>Activity Type</div>
                                                <div className='tableheader'>Participants</div>
                                                <div className='tableheader'>Description</div>
                                            </div>

                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>UI/UX designer</div>
                                                <div className='tableheaderbody'>Independent - Wireframe creation</div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>
                                                    The UI/UX designer will prepare all the wireframes for the post-login consumer application (WEB).
                                                </div>
                                            </div>

                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call</div>
                                                <div className='tableheaderbody'>UI/UX designer, Founder</div>
                                                <div className='tableheaderbody'>
                                                    The UI/UX designer presents each of the screens to the Founder. The founder can request changes which would invoke another round of calls. Or he can sign off.
                                                </div>
                                            </div>

                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>UI/UX designer</div>
                                                <div className='tableheaderbody'>Independent - Wireframe creation</div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>
                                                    The UI/UX designer will prepare all the wireframes for the post-login consumer application (Mobile).
                                                </div>
                                            </div>

                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call</div>
                                                <div className='tableheaderbody'>UI/UX designer, Founder</div>
                                                <div className='tableheaderbody'>
                                                    The UI/UX designer presents each of the screens to the Founder. The founder can request changes which would invoke another round of calls. Or he can sign off.
                                                </div></div>
                                        </>
                                    </div>
                                }


                            </div>
                        </>
                    }


                    {
                        seedata?.month == "2" &&
                        <>
                            <div className='listofcard'>
                                <div className='weekbio'
                                    onClick={() => {
                                        setseedata({
                                            month: "2",
                                            week: "3",
                                            toggle: seedata?.week == "3" ? !seedata?.toggle : true,
                                        })
                                    }}
                                >
                                    <div className='subboldheader'>Week 3 -  Vendor/Admin application wireframes

                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                        <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                                    </svg>
                                </div>
                                {
                                    seedata?.month == "2" && seedata?.week == "3" && seedata?.toggle &&
                                    <div className='cutomdata'>
                                        <>
                                            <div className='tablelist'>
                                                <div className='tableheader'>Leader</div>
                                                <div className='tableheader'>Activity Type</div>
                                                <div className='tableheader'>Participants</div>
                                                <div className='tableheader'>Description</div>
                                            </div>

                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>UI/UX designer</div>
                                                <div className='tableheaderbody'>Independent - Wireframe creation</div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>
                                                    The UI/UX designer will prepare all the wireframes for the vendor/admin applications (WEB).
                                                </div>
                                            </div>

                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call</div>
                                                <div className='tableheaderbody'>UI/UX designer, Founder</div>
                                                <div className='tableheaderbody'>
                                                    The UI/UX designer presents each of the screens to the Founder. The founder can request changes which would invoke another round of calls. Or he can sign off.
                                                </div>
                                            </div>

                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>UI/UX designer</div>
                                                <div className='tableheaderbody'>Independent - Wireframe creation</div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>
                                                    The UI/UX designer will prepare all the wireframes for the vendor/admin applications (MOBILE).
                                                </div>
                                            </div>

                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call</div>
                                                <div className='tableheaderbody'>UI/UX designer, Founder</div>
                                                <div className='tableheaderbody'>
                                                    The UI/UX designer presents each of the screens to the Founder. The founder can request changes which would invoke another round of calls. Or he can sign off.
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                }


                            </div>
                        </>
                    }

                    {
                        seedata?.month == "2" &&
                        <>
                            <div className='listofcard'>
                                <div className='weekbio'
                                    onClick={() => {
                                        setseedata({
                                            month: "2",
                                            week: "4",
                                            toggle: seedata?.week == "4" ? !seedata?.toggle : true,
                                        })
                                    }}
                                >
                                    <div className='subboldheader'>Week 4 -  Prototype

                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                        <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                                    </svg>
                                </div>
                                {
                                    seedata?.month == "2" && seedata?.week == "4" && seedata?.toggle &&
                                    <div className='cutomdata'>
                                        <>
                                            <div className='tablelist'>
                                                <div className='tableheader'>Leader</div>
                                                <div className='tableheader'>Activity Type</div>
                                                <div className='tableheader'>Participants</div>
                                                <div className='tableheader'>Description</div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>UI/UX designer</div>
                                                <div className='tableheaderbody'>Independent - Prototype creation</div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>
                                                    The UI/UX designer converts all the wireframes which have been signed off by the Founder into an end-to-end Figma prototype.
                                                </div>
                                            </div>

                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call</div>
                                                <div className='tableheaderbody'>UI/UX designer, Founder</div>
                                                <div className='tableheaderbody'>
                                                    The UI/UX designer presents the Figma prototype. The founder can request changes which would invoke another round of calls. Or he can sign off.
                                                </div>
                                            </div>

                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call</div>
                                                <div className='tableheaderbody'>To all stakeholders

                                                </div>
                                                <div className='tableheaderbody'>
                                                    The founder and implementation specialist will present the final prototype to all on and off projet stakeholders.


                                                </div>
                                            </div>
                                        </>
                                    </div>
                                }


                            </div>
                        </>
                    }
                    <div className='listofcard'>
                        <div className='sublistofcard'
                            onClick={() => {
                                setseedata({
                                    month: seedata?.month == "3" ? "0" : "3",
                                    week: "",
                                    toggle: false,
                                })
                            }}
                        >
                            <div className='boldheader'>Month 3: Technical Documentation </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                            </svg>
                        </div>
                    </div>
                    {
                        seedata?.month == "3" &&
                        <div className='cutomdata'>
                            <>
                                <div className='listofcard'>
                                    <div className='weekbio'
                                        onClick={() => {
                                            setseedata({
                                                month: "3",
                                                week: "1",
                                                toggle: seedata?.week == "1" ? !seedata?.toggle : true,
                                            })
                                        }}
                                    >
                                        <div className='subboldheader'>Week 1 - Use case systems
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                            <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                                        </svg>
                                    </div>
                                    {
                                        seedata?.month == "3" && seedata?.week == "1" && seedata?.toggle &&
                                        <>  <div className='tablelist'>
                                            <div className='tableheader'>Leader</div>
                                            <div className='tableheader'>Activity Type</div>
                                            <div className='tableheader'>Participants</div>
                                            <div className='tableheader'>Description</div>
                                        </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Development Specialist</div>
                                                <div className='tableheaderbody'>Independent - Creation of all use case documentation</div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>The development specialist creates all the use case documentation.</div>
                                            </div>

                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call</div>
                                                <div className='tableheaderbody'>Development Specialist, Founder</div>
                                                <div className='tableheaderbody'>
                                                    The development specialist presents all the use case documentation to the Founder.
                                                    The founder can request changes which would invoke another round of calls. Or he can sign off.
                                                </div>
                                            </div>
                                        </>
                                    }


                                </div>
                            </>
                        </div>
                    }

                    {
                        seedata?.month == "3" &&
                        <>
                            <div className='listofcard'>
                                <div className='weekbio'
                                    onClick={() => {
                                        setseedata({
                                            month: "3",
                                            week: "2",
                                            toggle: seedata?.week == "2" ? !seedata?.toggle : true,
                                        })
                                    }}
                                >
                                    <div className='subboldheader'>Week 2 - Pre-development architecture documents
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                        <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                                    </svg>
                                </div>
                                {
                                    seedata?.month == "3" && seedata?.week == "2" && seedata?.toggle &&
                                    <div className='cutomdata'>
                                        <>  <div className='tablelist'>
                                            <div className='tableheader'>Leader</div>
                                            <div className='tableheader'>Activity Type</div>
                                            <div className='tableheader'>Participants</div>
                                            <div className='tableheader'>Description</div>
                                        </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Development Specialist</div>
                                                <div className='tableheaderbody'>Independent - Creation of all pre-development dev documents</div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'
                                                    style={{
                                                        flexDirection: "column"
                                                    }}
                                                >
                                                    The development specialist creates:
                                                    <ul>
                                                        <li>1. System architecture document</li>
                                                        <li>2. Database design document</li>
                                                        <li>3. API design document</li>
                                                        <li>4. Microservices and service-oriented architecture (SOA) document</li>
                                                        <li>5. Frontend architecture document</li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call</div>
                                                <div className='tableheaderbody'>Development Specialist, Founder</div>
                                                <div className='tableheaderbody'>
                                                    The development specialist presents all the pre-development documentation to the Founder.
                                                    The founder can request changes which would invoke another round of calls. Or he can sign off.
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                }


                            </div>
                        </>
                    }

                    {
                        seedata?.month == "3" &&
                        <>
                            <div className='listofcard'>
                                <div className='weekbio'
                                    onClick={() => {
                                        setseedata({
                                            month: "3",
                                            week: "3",
                                            toggle: seedata?.week == "3" ? !seedata?.toggle : true,
                                        })
                                    }}
                                >
                                    <div className='subboldheader'>Week 3 - DevOps & infrastructure documentation
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                        <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                                    </svg>
                                </div>
                                {
                                    seedata?.month == "3" && seedata?.week == "3" && seedata?.toggle &&
                                    <div className='cutomdata'>
                                        <>  <div className='tablelist'>
                                            <div className='tableheader'>Leader</div>
                                            <div className='tableheader'>Activity Type</div>
                                            <div className='tableheader'>Participants</div>
                                            <div className='tableheader'>Description</div>
                                        </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Development Specialist</div>
                                                <div className='tableheaderbody'>Independent - Creation of all DevOps & infrastructure documents</div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>The development specialist creates
                                                    <ul>
                                                        <li>1. Infrastructure and cloud architecture document</li>
                                                        <li>2. Security architecture document</li>
                                                        <li>3. DevOps and monitoring document</li>
                                                        <li>4. Testing and quality assurance document</li>
                                                        <li>5. Cloud cost report</li>
                                                    </ul>

                                                </div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call</div>
                                                <div className='tableheaderbody'>Development Specialist, Founder</div>
                                                <div className='tableheaderbody'>
                                                    The development specialist presents all the DevOps & Infrastructure documents to the Founder.
                                                    The founder can request changes which would invoke another round of calls. Or he can sign off.
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                }


                            </div>
                        </>
                    }


                    {
                        seedata?.month == "3" &&
                        <>
                            <div className='listofcard'>
                                <div className='weekbio'
                                    onClick={() => {
                                        setseedata({
                                            month: "3",
                                            week: "4",
                                            toggle: seedata?.week == "4" ? !seedata?.toggle : true,
                                        })
                                    }}
                                >
                                    <div className='subboldheader'>Week 4 - Integration documents + AMC structure
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                        <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                                    </svg>
                                </div>
                                {
                                    seedata?.month == "3" && seedata?.week == "4" && seedata?.toggle &&
                                    <div className='cutomdata'>
                                        <>  <div className='tablelist'>
                                            <div className='tableheader'>Leader</div>
                                            <div className='tableheader'>Activity Type</div>
                                            <div className='tableheader'>Participants</div>
                                            <div className='tableheader'>Description</div>
                                        </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Development Specialist</div>
                                                <div className='tableheaderbody'>Independent - Creation of all Integration documentation</div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>
                                                    The development specialist creates:
                                                    <ul>
                                                        <li>1. Integration roadmap for each 3rd party software</li>
                                                        <li>2. Integration cost report</li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call</div>
                                                <div className='tableheaderbody'>Development Specialist, Founder</div>
                                                <div className='tableheaderbody'>
                                                    The development specialist presents all the Integration documentation to the Founder.
                                                    The founder can request changes which would invoke another round of calls. Or he can sign off.
                                                </div>
                                            </div>

                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Independent - Creation of all AMC structure</div>
                                                <div className='tableheaderbody'>NA</div>
                                                <div className='tableheaderbody'>
                                                    The development specialist and implementation specialist co-create a final AMC document which will highlight to stakeholders what will be the cost of maintaining the product post-launch and the user-level unit cloud cost for each user.
                                                </div>
                                            </div>
                                            <div className='tablelistbody'>
                                                <div className='tableheaderbody'>Implementation Specialist</div>
                                                <div className='tableheaderbody'>Collective - Call
                                                </div>
                                                <div className='tableheaderbody'>To all stakeholders

                                                </div>
                                                <div className='tableheaderbody'>
                                                    The implementation specialist and founder present to all stakeholders the development roadmap and also the DevOps maintenance game plan.


                                                </div>
                                            </div>
                                        </>
                                    </div>
                                }


                            </div>
                        </>
                    }
                </>
            }

            {
                infodetail == "Deliverables" &&
                <>

                    <div className='listofcard'
                        style={{
                            marginTop: "2rem"
                        }}
                    >
                        <div className='sublistofcard'
                            onClick={() => {
                                setdatalist({
                                    name: datalist?.name == "Non-Technical Documents" ? "" : "Non-Technical Documents",
                                    toggle: false,
                                })
                            }}
                        >
                            <div className='boldheader'>Non-Technical Documents</div>
                            <svg
                                style={{
                                    transform: datalist?.name == "Non-Technical Documents" && "rotate(180deg)"
                                }}
                                xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                            </svg>
                        </div>
                    </div>

                    {
                        datalist?.name == "Non-Technical Documents" &&
                        <div className='delivarysection'>
                            <div className='delivarysectionCard'>
                                <div className='toplist'>
                                    BRD
                                </div>
                                <div className='Nameofcards'>Business Requirements Documents</div>
                            </div>
                            <div className='delivarysectionCard'>
                                <div className='toplist'>
                                    PRD
                                </div>
                                <div className='Nameofcards'>Product Requirements Documents</div>
                            </div>
                            <div className='delivarysectionCard'>
                                <div className='toplist'>
                                    LK
                                </div>
                                <div className='Nameofcards'>Logo Kit</div>
                            </div>
                            <div className='delivarysectionCard'>
                                <div className='toplist'>
                                    PCAR
                                </div>
                                <div className='Nameofcards'>Product competitive analysis report</div>
                            </div>
                            <div className='delivarysectionCard'>
                                <div className='toplist'>
                                    UDR
                                </div>
                                <div className='Nameofcards'>UI/UX design report</div>
                            </div>
                            <div className='delivarysectionCard'>
                                <div className='toplist'>
                                    UFM
                                </div>
                                <div className='Nameofcards'>User flow map</div>
                            </div>
                            <div className='delivarysectionCard'>
                                <div className='toplist'>
                                    TAD
                                </div>
                                <div className='Nameofcards'>Technical architecture document</div>
                            </div>
                            <div className='delivarysectionCard'>
                                <div className='toplist'>
                                    SAD
                                </div>
                                <div className='Nameofcards'>Security architecture document</div>
                            </div>
                            <div className='delivarysectionCard'>
                                <div className='toplist'>
                                    AIR
                                </div>
                                <div className='Nameofcards'>Ai integration report</div>
                            </div>
                            <div className='delivarysectionCard'>
                                <div className='toplist'>
                                    IAMD
                                </div>
                                <div className='Nameofcards'>Infrastructure and maintenance document</div>
                            </div>
                        </div>

                    }
                    <div className='listofcard'
                        style={{
                            marginTop: "2rem"
                        }}
                    >
                        <div className='sublistofcard'
                            onClick={() => {
                                setdatalist({
                                    name: datalist?.name == "Technical Documents" ? "" : "Technical Documents",
                                    toggle: false,
                                })
                            }}
                        >
                            <div className='boldheader'>Technical Documents</div>
                            <svg
                                style={{
                                    transform: datalist?.name == "Technical Documents" && "rotate(180deg)"
                                }}
                                xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                            </svg>
                        </div>
                    </div>
                    {/* {
                datalist?.name == "Technical Assetjs" &&
                <div className='delivarysection'>
                    <div className='delivarysectionCard'>
                        <div className='toplist'>
                            BRD
                        </div>
                        <div className='Nameofcards'>UI/UX Figma files</div>
                    </div>
                    <div className='delivarysectionCard'>
                        <div className='toplist'>
                            PRD
                        </div>
                        <div className='Nameofcards'>page pre-login website</div>
                    </div>
                    <div className='delivarysectionCard'>
                        <div className='toplist'>
                            LK
                        </div>
                        <div className='Nameofcards'>Mobile responsiveness</div>
                    </div>
                    <div className='delivarysectionCard'>
                        <div className='toplist'>
                            PCAR
                        </div>
                        <div className='Nameofcards'>web app or mobile application </div>
                    </div>
                    <div className='delivarysectionCard'>
                        <div className='toplist'>
                            UDR
                        </div>
                        <div className='Nameofcards'>Admin panel - web app </div>
                    </div>
                    <div className='delivarysectionCard'>
                        <div className='toplist'>
                            UFM
                        </div>
                        <div className='Nameofcards'>1 intermediary - web app or mobile application </div>
                    </div>
                    <div className='delivarysectionCard'>
                        <div className='toplist'>
                            TAD
                        </div>
                        <div className='Nameofcards'>Technical architecture document</div>
                    </div>
                    <div className='delivarysectionCard'>
                        <div className='toplist'>
                            SAD
                        </div>
                        <div className='Nameofcards'>Security architecture document</div>
                    </div>
                    <div className='delivarysectionCard'>
                        <div className='toplist'>
                            AIR
                        </div>
                        <div className='Nameofcards'>Ai integration report</div>
                    </div>
                    <div className='delivarysectionCard'>
                        <div className='toplist'>
                            IAMD
                        </div>
                        <div className='Nameofcards'>Infrastructure and maintenance document</div>
                    </div>
                </div>

            } */}
                    <div className='listofcard'
                        style={{
                            marginTop: "2rem"
                        }}
                    >
                        <div className='sublistofcard'
                            onClick={() => {
                                setdatalist({
                                    name: datalist?.name == "Applications" ? "" : "Applications",
                                    toggle: false,
                                })
                            }}
                        >
                            <div className='boldheader'>Applications</div>
                            <svg
                                style={{
                                    transform: datalist?.name == "Applications" && "rotate(180deg)"
                                }}
                                xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                            </svg>
                        </div>
                    </div>
                    <div className='listofcard'
                        style={{
                            marginTop: "2rem"
                        }}
                    >
                        <div className='sublistofcard'
                            onClick={() => {
                                setdatalist({
                                    name: datalist?.name == "Integrations" ? "" : "Integrations",
                                    toggle: false,
                                })
                            }}
                        >
                            <div className='boldheader'>Integrations</div>
                            <svg
                                style={{
                                    transform: datalist?.name == "Integrations" && "rotate(180deg)"
                                }}
                                xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                            </svg>
                        </div>
                    </div>

                    <div className='listofcard'
                        style={{
                            marginTop: "2rem"
                        }}
                    >
                        <div className='sublistofcard'
                            onClick={() => {
                                setdatalist({
                                    name: datalist?.name == "Ai Infrastructure" ? "" : "Ai Infrastructure",
                                    toggle: false,
                                })
                            }}
                        >
                            <div className='boldheader'>Ai Infrastructure</div>
                            <svg
                                style={{
                                    transform: datalist?.name == "Ai Infrastructure" && "rotate(180deg)"
                                }}
                                xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                            </svg>
                        </div>
                    </div>
                    <div className='listofcard'
                        style={{
                            marginTop: "2rem"
                        }}
                    >
                        <div className='sublistofcard'
                            onClick={() => {
                                setdatalist({
                                    name: datalist?.name == "Devops" ? "" : "Devops",
                                    toggle: false,
                                })
                            }}
                        >
                            <div className='boldheader'>Devops</div>
                            <svg
                                style={{
                                    transform: datalist?.name == "Devops" && "rotate(180deg)"
                                }}
                                xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                            </svg>
                        </div>
                    </div>
                    <div className='listofcard'
                        style={{
                            marginTop: "2rem"
                        }}
                    >
                        <div className='sublistofcard'
                            onClick={() => {
                                setdatalist({
                                    name: datalist?.name == "Add ons" ? "" : "Add ons",
                                    toggle: false,
                                })
                            }}
                        >
                            <div className='boldheader'>Add ons</div>
                            <svg
                                style={{
                                    transform: datalist?.name == "Add ons" && "rotate(180deg)"
                                }}
                                xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M17 15.0521L17 1.94792C17 0.873375 16.1266 0 15.0521 0L1.94792 0C0.873375 0 0 0.873375 0 1.94792L0 15.0521C0 16.1266 0.873375 17 1.94792 17L15.0521 17C16.1266 17 17 16.1266 17 15.0521ZM7.98292 11.1095L3.73292 6.57192C3.60471 6.4345 3.54167 6.26096 3.54167 6.08742C3.54167 5.89829 3.61675 5.70988 3.7655 5.57104C4.05167 5.30258 4.50004 5.31746 4.76708 5.60292L8.5 9.58871L12.2329 5.60292C12.5 5.31746 12.9483 5.30258 13.2345 5.57033C13.5192 5.83667 13.5341 6.28504 13.2671 6.57121L9.01708 11.1088C8.88321 11.2526 8.6955 11.3333 8.5 11.3333C8.3045 11.3333 8.11679 11.2526 7.98292 11.1095Z" fill="black" />
                            </svg>
                        </div>
                    </div>
                </>
            }
        </div >
    )
}
