import React from 'react'
import './Sectionfoure.scss'
export default function index() {
    return (
        <div className='sectionfour'>
            <div className='innerfour'>

                <div className='linetext'> Your EarnGPT journey starts with taking our <br />EarnGPT pro course which will include</div>
                <div className='border-div-class'>

                    <ul>
                        <li>1. Introduction to AI and Its Business Applications</li>
                        <li>2.Understanding AI Agents and How to Use Them

                        </li>
                        <li>3. Leveraging GPT Technology for Business Success</li>
                        <li>4. Exploring the AI Tool Ecosystem</li>
                        <li>5. Designing AI Strategies for Businesses in Transformation</li>
                        <li>6. Practical Case Studies and Real-World AI Implementations</li>
                        <li>7. Building Your AI Consultancy: Tools, Tactics, and Opportunities</li>
                    </ul>
                    <br />       <br />
                    <div className='label'
                        onClick={() => window.open("https://join.garageuniversity.app/web/checkout/66eb3d4c48dee9a0c6999e05", "_blank")}
                    >Apply Now</div>

                </div>
            </div>
        </div>
    )
}
